import { Injectable } from "@angular/core";
import { ApiService } from "@services/api.service";
import { ResponseBidCandidateActivities } from "@wearewarp/types-server-admin/carrier-bid";
import { ApiUri } from "./const";
import { Subject, Subscription } from "rxjs";

@Injectable({ providedIn: 'root' })
export class DataRepoBid {

  constructor(private api: ApiService) {}

  private subCandidateActivities: {[key: string]: Subject<{error?: any, data?: ResponseBidCandidateActivities}>} = {};
  fetchCandidateActivities(candidateId: string, onNext: (data: {error?: any, data?: ResponseBidCandidateActivities}) => void): Subscription {
    const key = candidateId;
    if (!this.subCandidateActivities[key]) {
      this.subCandidateActivities[key] = new Subject();
      const url = ApiUri.BidCandidate.bidActivities(candidateId);
      this.api.get<ResponseBidCandidateActivities>(url).subscribe(
        resp => {
          this.subCandidateActivities[key].next({data: resp});
          this.subCandidateActivities[key] = undefined;
        }, err => {
          this.subCandidateActivities[key].next({error: err});
          this.subCandidateActivities[key] = undefined;
        }
      )
    }
    return this.subCandidateActivities[key].subscribe(onNext);
  }
}