import { Injectable } from "@angular/core";
import { ApiService } from "@services/api.service";
import { ApiUri } from "./const";
import {
  RequestBidSessionCreate,
  RequestBidSessionSearchRoutes,
  RequestBidSessionUpdate,
  ResponseBidSessionCreate,
  ResponseBidSessionDetail,
  ResponseBidSessionSearchRoutesItem,
  RequestAddCandidateToSession
} from "@wearewarp/types-server-admin/carrier-bid/bid-session";
import { PaginationData } from "@wearewarp/types/common/interface";

@Injectable({ providedIn: 'root' })
export class DataRepoBidSession {
  constructor(private api: ApiService) {}

  createSession(data: RequestBidSessionCreate) {
    return this.api.post<ResponseBidSessionCreate>(ApiUri.BidSession.create, data);
  }

  getSessionDetail(sessionId: string) {
    return this.api.get<ResponseBidSessionDetail>(ApiUri.BidSession.detail(sessionId));
  }

  updateSession(sessionId: string, data: RequestBidSessionUpdate) {
    return this.api.patch<void>(ApiUri.BidSession.detail(sessionId), data);
  }

  deleteSession(sessionId: string) {
    return this.api.delete<void>(ApiUri.BidSession.detail(sessionId));
  }

  searchRoutes(filter: RequestBidSessionSearchRoutes) {
    return this.api.post<PaginationData<ResponseBidSessionSearchRoutesItem>>(ApiUri.BidSession.searchRoutes, filter);
  }

  getRoutesRecommend(sessionId: string) {
    return this.api.get<PaginationData<ResponseBidSessionSearchRoutesItem>>(ApiUri.BidSession.getRoutesRecommend(sessionId));
  }

  getRoutesAdded(sessionId: string) {
    return this.api.get<PaginationData<ResponseBidSessionSearchRoutesItem>>(ApiUri.BidSession.getRoutesAddded(sessionId));
  }

  addRouteToSession(sessionId: string, routeId: string) {
    return this.api.post(ApiUri.BidSession.addRoute(sessionId), {jobIds: [routeId]});
  }

  addRouteToSessions(sessionId: string, routeIds: string[]) {
    return this.api.post(ApiUri.BidSession.addRoute(sessionId), {jobIds: routeIds});
  }

  removeRouteFromSession(sessionId: string, routeId: string) {
    return this.api.post(ApiUri.BidSession.removeRoute(sessionId), {jobId: routeId});
  }
  
  addCandidates(sessionId: string, data: RequestAddCandidateToSession) {
    return this.api.POST(ApiUri.BidSession.addCandidates(sessionId), data);
  }

}