import { Const as WarpConst, UnitConverter } from "@wearewarp/universal-libs";
import { OrderStatusNew, SizeUnit, WeightUnit } from "@wearewarp/types";
import { CostLevel as CostLevelEnum } from "@wearewarp/types-server-admin/form-data/shipment-entry";
import { Utils } from "@services/utils";
import { AppConst } from "@app/const.generated";
import { environment } from '@env/environment';
import { ClientAppId } from "@wearewarp/js-const-app-id";
import { IssueTicketType } from '@wearewarp/types/data-model';

export class Const {

  public static readonly routeLogin = `/login`;
  public static readonly routeVerifyLogin = `/verify`;

  public static readonly routeDashboard = '/dashboard';
  public static readonly routeAdminUserList = `${this.routeDashboard}/users`;
  public static readonly routeAdminDeveloperList = `${this.routeDashboard}/developers`;
  public static readonly routeAdminCarrierList = `${this.routeDashboard}/carriers`;
  public static readonly routeAdminDriverList = `${this.routeDashboard}/drivers`;
  public static readonly routeAdminClientList = `${this.routeDashboard}/customers`;
  public static readonly routeAdminDispatchList = `${this.routeDashboard}/dispatch`;
  public static readonly routeAdminOrderList = `${this.routeDashboard}/orders`;
  public static readonly routeRoutingTool = `https://route.wearewarp.link`

  public static readonly routeAdminPODNeedConfirm = `${this.routeDashboard}/finance-v2/pod-confirmation`;

  /**
   * @deprecated use routeAdminOrderList instead
   */
  public static readonly routeAdminShipmentList = `${this.routeDashboard}/orders`;
  public static readonly routeAdminShipmentParcels = `${this.routeAdminOrderList}/parcels`;
  public static readonly routeAdminCarrierSales = `${this.routeDashboard}/carrier-sales`;
  public static readonly routeAdminBidSessions = `${this.routeDashboard}/bid-sessions`;
  public static readonly routeAdminCarrierBidList = `${this.routeDashboard}/carrier-bids`;
  public static readonly routeAdminPools = `${this.routeDashboard}/pools`;
  public static readonly routeAdminCrossdock = `${this.routeDashboard}/cross-dock`;
  public static readonly routeAdminCrossdockWarehouseJob = `${this.routeAdminCrossdock}/shipments`;
  public static readonly routeAdminCrossdockWarehouseMap = `${this.routeAdminCrossdock}/warehouses-map`;
  public static readonly routeAdminCrossdockWarehouseManifests = `${this.routeAdminCrossdock}/manifests`;
  public static readonly routeAdminLabelTemplateList = `${this.routeDashboard}/settings/label-templates`;
  public static readonly routeAdminLocationList = `${this.routeDashboard}/locations`;
  public static readonly routeAdminCustomerLocationList = `${this.routeDashboard}/customer-locations`;
  public static readonly routeAdminCustomerLaneList = `${this.routeDashboard}/customer-lanes`;
  public static readonly routeAdminFreightQuote = `${this.routeDashboard}/freight-quote`;
  public static readonly routeAdminFreightQuoteFtl = `${this.routeDashboard}/freight-quote-ftl`;
  public static readonly routeAdminAuditLogList = `${this.routeDashboard}/audit-logs`;
  public static readonly routeSettingsLocations = `${this.routeDashboard}/settings/locations`;
  public static readonly routeSetting2FA = `${this.routeDashboard}/settings/two-factor-authentication`;
  public static readonly routeAdminShipmentQueue = `${this.routeDashboard}/cross-dock/shipment-queues`;
  public static readonly routePublicShipWithUs = '/ship-with-us';
  public static readonly routePublicFormCarrier = '/become-carrier';
  public static readonly routePublicFormFacility = '/become-facility';
  public static readonly routeRoutingLTL = `${this.routeDashboard}/routing-ltl`;
  public static readonly routeRoutingLTLProblems = `${this.routeRoutingLTL}/problems`;
  public static readonly routeAdminFinanceTransaction = `${this.routeDashboard}/finance/transactions`;
  public static readonly routeAdminWarehouseRatePlan = `${this.routeAdminCrossdock}/rate-configuration`;
  public static readonly routeAdminOrganization = `${this.routeDashboard}/organizations`;
  public static readonly routeAdminDedicatedLanes = `${this.routeDashboard}/dedicated-lanes`;
  public static readonly handleFTLJobInNodeJS = true;

  public static readonly shouldShowEditModeBtnShipmentEntry = false;
  public static readonly defaultTurnOnEditMode = true;

  public static readonly routeReportViewer = `${this.routeDashboard}/report/viewer`;
  // PLANNING
  public static readonly routePlanning = `${this.routeDashboard}/planning`;
  public static readonly routePlanningSessions = `${this.routeDashboard}/planning/sessions`;
  public static readonly routePlanningNeedRouting = `${this.routeDashboard}/planning/need-routing`;
  public static readonly routePlanningLinehauls = `${this.routeDashboard}/planning/linehauls`;
  public static readonly routePlanningLinehaulsV2 = `${this.routeDashboard}/planning/linehauls-v2`;

  // FINANCE
  public static readonly routeAdminFinAP = `${this.routeDashboard}/finance/ap`;
  public static readonly routeAdminFinAR = `${this.routeDashboard}/finance/ar`;
  public static readonly routeAdminFinQBStatement = `${this.routeDashboard}/finance/qb-statement`;

  // PLANNING
  public static readonly routeTemplates = `${this.routeDashboard}/templates`;
  public static readonly routeAnnouncements = `${this.routeDashboard}/announcements`;

  // API URI
  public static readonly APIURI_TIMEOUT_TEST = 'http://google.com:81';

  public static APIV2(path: string): string { return `v2/${path}` }
  public static API_SESSION(): string { return 'ss?e=1' }
  public static API_ME(action: string = null): string { return action ? `me/${action}` : 'me' }
  public static API_PUBLIC(action: string): string { return `p/${action}` }
  public static APIURI_LTL_ROUTING(action: string): string { return `ltl_routing/${action}` }
  public static APIURI_SHIPMENT_QUEUE(action?: string): string { return action ? Const.APIV2(`shipment_queue/${action}`) : Const.APIV2(`shipment_queue`) }
  public static APIURI_QUICK_ORDERS(action?: string): string { return action ? Const.APIV2(`orders/quick_orders/${action}`) : Const.APIV2(`orders/quick_orders`) }
  public static APIURI_MARKETPLACE(action?: string): string { return action ? Const.APIV2(`marketplaces/${action}`) : Const.APIV2(`marketplaces`) }
  public static API_WAREHOUSE_MANAGEMENT(path: string): string { return Const.APIV2(`warehouse_management/${path}`) }
  public static readonly APIURI_MY_PROFILE = Const.API_ME('my_profile');
  public static readonly APIURI_DOWNLOAD = 'dl';
  public static readonly APIURI_USERS = 'users';
  public static readonly APIURI_CARRIERS = 'carriers';
  public static readonly APIURI_CARRIERS_FOR_FILTER = 'carriers/list/all_for_filter';
  public static readonly APIURI_POOLS = 'pools';
  public static readonly APIURI_CARRIER_BIDS = 'carrier_bids';
  public static readonly APIURI_DRIVERS = 'drivers';
  public static readonly APIURI_DRIVERS_FOR_FILTER = 'drivers/list/all_for_filter';
  public static readonly APIURI_SHIPMENT_INVOICE = 'shipment-invoices';
  public static readonly APIURI_SHIPMENTS = 'shipments';
  public static readonly APIURI_SHIPMENT_ITEMS = 'shipment_items';
  public static readonly APIURI_ORDERS = 'orders';
  public static readonly APIURI_INVOICES_ORDERS = 'invoices/orders';
  public static readonly APIURI_ORDERS_V3 = 'orders-v3';
  public static readonly APIURI_ORDERS_V4 = 'orders-v4';
  public static readonly APIURI_ORDERS_V5 = 'orders-v5';
  public static readonly APIURI_ORDER_ITEMS = 'order-items';
  public static readonly APIURI_CLIENTS = 'clients';
  public static readonly APIURI_CLIENTS_FOR_FILTER = 'clients/list/all_for_filter';
  public static readonly APIURI_AUDIT_LOGS = 'audit_logs';
  public static readonly APIURI_COMM_LOGS = 'comm_logs';
  public static readonly APIURI_QUOTE_ORDER = 'rating/shipments/';
  public static readonly APIURI_GET_QUOTE = 'rating/quotes';
  public static readonly APIURI_FINANCE_SETTINGS = 'settings';
  public static readonly APIURI_FINANCE_FORMULA = 'formula';
  public static readonly APIURI_FINANCE_CONFIG = 'system-config';
  public static readonly APIURI_MANUAL_ROUTE = 'v2/orders/manual-route' //migrate to v2
  public static readonly APIURI_WAREHOUSES = 'warehouses';
  public static readonly APIURI_WAREHOUSES_FOR_FILTER = 'warehouses/list/all_for_filter';
  public static readonly APIURI_ORGANIZATIONS = 'organizations';
  public static readonly APIURI_JOBS = 'jobs';
  public static readonly APIURI_BID_SESSIONS = 'v2/bid-sessions';
  public static readonly APIURI_JOBS_V2 = 'jobs-v2'
  public static readonly APIURI_CONVERSATIONS = 'v2/conversations';
  public static readonly APIURI_JOB_NOTIFICATIONS = 'job_notifications';
  public static readonly APIURI_TASKS = 'tasks';
  public static readonly APIURI_DEVELOPERS = 'developers';
  public static readonly APIURI_PERFORMANCE = 'performance';
  public static readonly APIURI_SETTINGS = 'settings';
  public static readonly APIURI_INVENTORIES = 'inventories';
  public static readonly APIURI_LABEL_TEMPLATES = 'label_templates';
  public static readonly APIURI_FLEETS = 'fleets';
  public static readonly APIURI_FREIGHT_QUOTE = 'freight-quote';
  public static readonly APIURI_FREIGHT_QUOTE_LOGS = 'freight_quote_logs';
  public static readonly APIURI_SERVICE_OPTIONS = 'service_options';
  public static readonly APIURI_ZIP_RATE = 'zip_rate';
  public static readonly APIURI_STATISTIC = 'statistic';
  public static readonly APIURI_METADATA = 'metadata';
  public static readonly APIURI_LOCATION_SETTING = 'location_settings';
  public static readonly APIURI_TEMPLATES = 'templates'
  public static readonly APIURI_ANNOUNCEMENTS = 'announcements'
  public static readonly APIURI_COMM_UNSUBSCRIBE = Const.APIV2('comm_unsubscribes');
  public static readonly APIURI_LOCATION = Const.APIV2('locations');
  public static readonly APIURI_UPDATE_CARRIER_BID_INFO = Const.API_PUBLIC('update_bid_info');
  public static readonly APIURI_APP_DOWNLOAD = Const.API_PUBLIC('app-crossdock-history');
  public static readonly API_SEARCH_US_CITIES = 'https://gw.wearewarp.com/us-cities/v1/search/city';
  public static readonly APIURI_SNOOZE = 'snooze';
  public static readonly APIURI_PODS = 'pods';
  public static readonly APIURI_CARRIER_LOOKUP = Const.APIV2('carrier_lookup');
  public static readonly APIURI_JOB_TRACKING_TASKS = Const.APIV2('job_tracking_tasks');
  public static readonly APIURI_GET_SHIPMENTS_FOR_ROUTING = Const.APIV2(`${Const.APIURI_SHIPMENTS}/get-for-routing`);
  public static readonly APIURI_THIRD_PARTY = Const.APIV2('third-party');
  public static readonly APIURI_THIRD_PARTY_POST = Const.APIV2('third-party/post'); // will change to thirdparty soon
  public static readonly APIURI_FILTER_INFO = Const.APIV2('filter-info');
  public static readonly APIURI_POOL_PROJECT = Const.APIV2('pool-projects');
  public static readonly APIURI_POOL_MARKET = Const.APIV2('pool-markets');


  public static APIURI_FINANCES_BATCH(path?: string): string { return path ? Const.APIV2(`finances/batches/${path}`) : Const.APIV2(`finances/batches`) }
  public static APIURI_FINANCES_STATEMENT(path?: string): string { return path ? Const.APIV2(`finances/statement/${path}`) : Const.APIV2(`finances/statement`) }
  public static APIURI_FINANCES_PAYABLE(path?: string): string { return path ? Const.APIV2(`finances/payable/${path}`) : Const.APIV2(`finances/payable`) }
  public static APIURI_FINANCES_QB_STATEMENT(path?: string): string { return path ? Const.APIV2(`finances/quickbook_statements/${path}`) : Const.APIV2(`finances/quickbook_statements`) }
  public static APIURI_POD_CONFIRMATION_V2(path?: string): string { return path ? Const.APIV2(`pod_confirmation/${path}`) : Const.APIV2(`pod_confirmation`) }
  public static readonly APIURI_FINANCES = 'finances';
  public static readonly APIURI_POD_CONFIRMATION = 'pod_confirmation';
  public static readonly APIURI_SELECT_WAREHOUSES = Const.API_WAREHOUSE_MANAGEMENT('selection_warehouses');
  public static readonly APIURI_WAREHOUSE_SHIPMENT = Const.API_WAREHOUSE_MANAGEMENT('shipments');
  public static readonly APIURI_WAREHOUSE_ITEM = Const.API_WAREHOUSE_MANAGEMENT('items');
  public static readonly APIURI_WAREHOUSE_TASK = Const.API_WAREHOUSE_MANAGEMENT('tasks');
  public static readonly APIURI_WAREHOUSE_JOB = Const.API_WAREHOUSE_MANAGEMENT('jobs');
  public static readonly APIURI_WAREHOUSE_JOB_SELECTED = Const.API_WAREHOUSE_MANAGEMENT('jobs/selected');
  public static readonly APIURI_WAREHOUSE_EXPORT_JOB = Const.API_WAREHOUSE_MANAGEMENT('jobs/export');
  public static readonly APIURI_WAREHOUSE_UPLOAD_FILES = Const.API_WAREHOUSE_MANAGEMENT('upload_files');
  public static readonly APIURI_WAREHOUSE_UPLOAD_FILE = Const.API_WAREHOUSE_MANAGEMENT('upload_file');
  public static readonly APIURI_WAREHOUSE_DOWNLOAD_BOL = Const.API_WAREHOUSE_MANAGEMENT('download_bols');
  public static readonly APIURI_WAREHOUSE_EXPORT_PALLET = Const.API_WAREHOUSE_MANAGEMENT('jobs/export_pallets');
  public static readonly APIURI_WAREHOUSE_RATE_PLAN = Const.API_WAREHOUSE_MANAGEMENT('rate_plans');
  public static readonly APIURI_WAREHOUSE_EXTERNAL_ROUTE_CONNECTING = Const.API_WAREHOUSE_MANAGEMENT('external_route_connecting');
  public static readonly APIURI_WAREHOUSE_MANIFEST = `${environment.restWarehouseBackendUrl}/v1/admin/manifests`;
  public static readonly APIURI_FINANCE_TRANSACTIONS = 'payments/transactions';
  public static API_GET_CARRIER_FOR_ASSIGN = Const.APIV2(`${Const.APIURI_CARRIERS}/get-selection-for-assign`);
  public static API_GET_CARRIER_DETAIL_FOR_JOB(id: string): string { return Const.APIV2(`${Const.APIURI_CARRIERS}/${id}/get-detail-for-job`) }
  public static API_GET_STOP_DETAIL_FOR_JOB(stopId: string, jobId: string): string { return Const.APIV2(`${Const.APIURI_JOBS}/${jobId}/get-stop-detail-for-job/${stopId}`) }
  public static API_GET_DETAIL_FOR_CARRIER_COST(id: string): string { return Const.APIV2(`${Const.APIURI_JOBS}/${id}/get-detail-for-carrier-cost`) }
  public  static readonly APIURI_REPORTS_DOORDASH = Const.APIV2('reports/report-signature-count-for-doordash');
  public  static readonly APIURI_EXPORT_REPORTS_DOORDASH = Const.APIV2('reports/export-report-signature-count-for-doordash');
  public  static readonly APIURI_EXPORT_BARCODE_REPORT_DOORDASH = Const.APIV2('reports/export-report-barcode-for-doordash');

  /** Internal services */
  public static readonly API_SERVICE_COMM_UNSUBSCRIBE = `${environment.commlogServiceUrl}/comm_unsubscribe`;
  public static readonly API_SERVICE_COMM_LOG = `${environment.commlogServiceUrl}/comm_log`;
  public static readonly API_SERVICE_WORK_QUEUE = `${environment.workQueueServiceUrl}/v1`;
  public static readonly API_SERVICE_CARRIER_RATING = `${environment.carrierRatingServiceUrl}/v1`;
  public static readonly API_SERVICE_WAREHOUSE = `${environment.warehouseServiceUrl}/w`;

  public static readonly API_SEARCH_START_WITH = 1;
  public static readonly API_SEARCH_END_WITH = 2;
  public static readonly API_SEARCH_INCLUDE = 3;

  public static readonly ENCRYPT_KEY = 'y1pSrKRLLkCQGbt0rqA5DJh3XnjDG3j9';
  public static readonly ENCRYPT_IV = 'U0mkzdFK7naqEn60';
  public static readonly HASH_KEY = 'xGyF5W2H0UU8RHxAVvjwwVywd6hmQWTc';
  public static readonly CountryId_USA = '61e46975b8ead47e3020fbac';
  public static readonly CountryCodeAlpha2_USA = 'US';
  public static readonly PAGINATION_LIMIT = 10;
  public static readonly SortAsc = 1;
  public static readonly SortDesc = -1;
  public static readonly LANG_VI = 'vi';
  public static readonly LANG_EN = 'en';
  public static readonly SIDEBAR_FULL = 'SIDEBAR_FULL';
  public static readonly SIDEBAR_SMALL = 'SIDEBAR_SMALL';
  public static readonly MOBILE_WIDTH = 568;
  public static readonly DATETIME_FORMAT_FROM_DB = 'YYYY-MM-DD HH:mm:ss';

  public static readonly FORMAT_GUI_DATE = 'MMM D, YYYY';
  public static readonly FORMAT_GUI_DATE_V2 = 'MM/DD/YYYY';
  public static readonly FORMAT_GUI_DATE_V3 = 'YYYY-MM-DD';
  public static readonly FORMAT_GUI_DATE_SHORT = 'MMM D';
  public static readonly FORMAT_GUI_DATETIME = 'MMM D, YYYY, h:mm a';
  public static readonly FORMAT_GUI_DATETIME_SHORT = 'M/D/YY, h:mm a';
  public static readonly FORMAT_GUI_DATETIME_V2 = 'ddd, MMM D . h:mm a';
  public static readonly FORMAT_GUI_DATETIME_V3 = 'MM/DD/YYYY, h:mm A';
  public static readonly FORMAT_GUI_DATETIME_V4 = 'MMM D, YYYY h:mm a';
  public static readonly FORMAT_GUI_DATETIME_V5 = 'MMM D, h:mm a';
  public static readonly FORMAT_GUI_DATETIME_V6 = 'MM-DD-YYYY h:mm A';
  public static readonly FORMAT_GUI_TIME = 'h:mm a';
  public static readonly MAP_LOCATION_COLOR_PICK = '#226DEE';     // xanh
  public static readonly MAP_LOCATION_COLOR_DROP = '#F1C946';     // vàng
  public static readonly MAP_LOCATION_COLOR_DEFAULT = '#B42222';  // đổ
  public static readonly MAP_ROUTE_LINE_COLOR = 'rgba(45, 114, 210, 1)';
  public static readonly MAP_LOCATION_ICON_PICK = 'assets/img/map-pickup-icon.png';
  public static readonly MAP_LOCATION_ICON_DROP = 'assets/img/map-dropoff-icon.png';
  public static readonly MAP_LOCATION_ICON_DEFAULT = 'assets/img/map-pickup-icon.png';

  public static readonly INVENTORY_MAP_DEFAULT_TXT_COLOR = '#334155'; // đen
  public static readonly INVENTORY_MAP_EMPTY_BG_COLOR = '#F8FAFC'; // trắng
  public static readonly INVENTORY_MAP_FULL_BG_COLOR = 'rgba(45, 114, 210, 0.2)'; // xanh nhạt
  public static readonly INVENTORY_MAP_SELECTED_BG_COLOR = '#1802D0'; // xanh đậm
  public static readonly INVENTORY_MAP_SELECTED_TXT_COLOR = 'rgba(255, 255, 255, 1)'; // trắng

  public static readonly INVENTORY_EMPTY_STATUS = 0;
  public static readonly INVENTORY_FULL_STATUS = 1;

  public static readonly WAREHOUSE_MAP_MODAL_SHIPMENT = "SHIPMENT";
  public static readonly WAREHOUSE_MAP_MODAL_ITEM = "ITEM";

  public static readonly MAP_LOCATION_LA: [number, number] = [-118.2421, 34.0319];  // Los Angeles, CA [longitude, latitude]
  public static MAPBOX_STYLE(name: string): string {
    return `mapbox://styles/mapbox/${name}`;
  }
  public static readonly MAPBOX_STYLE_STREETS_V11 = Const.MAPBOX_STYLE('streets-v11');
  public static readonly MAPBOX_STYLE_LIGHT_V10 = Const.MAPBOX_STYLE('light-v10');

  public static readonly CARRIER_BID_ENABLED = false;

  public static readonly SHOW_CARRIER_SALES_LEGACY = false;

  public static readonly EV_TOGGLE_SIDEBAR = 'EV_TOGGLE_SIDEBAR';
  public static readonly password_len_min = 8;
  public static readonly password_len_max = 20;
  public static readonly TIMEOUT_DOWNLOAD = 1000 * 60 * 10;   // 10 minutes
  public static readonly TIMEOUT_FREIGHT_QUOTE = 1000 * 60 * 10;   // 10 minutes (chỗ này cần để timeout lớn vì có những lần chạy 3~4 phút vẫn chưa xong)
  public static readonly CLIENT_APP_ID: number = ClientAppId.AppAdmin;
  public static readonly APP_HTTP_HEADER: string = `${Const.CLIENT_APP_ID};${AppConst.Version};${AppConst.BuildWhen.replace(/[\/\s:]/g, '')}`;

  public static readonly PORTAL_LIST = {
    web_admin: 'web_admin',
    web_driver: 'web_driver',
    web_carrier: 'web_carrier',
    web_customer: 'web_customer',
    web_tracking: 'web_tracking',
    app_android_crossdock: 'app_android_crossdock',
  }

  public static get CarrierStatus() { return WarpConst.CarrierStatus }
  public static get CarrierStatusArray() { return WarpConst.CarrierStatusArray }

  public static get UserStatus() { return WarpConst.UserStatus }
  public static get UserStatusArray() { return WarpConst.UserStatusArray }

  public static get DriverStatus() { return WarpConst.DriverStatus }

  public static get OrderStatus() { return WarpConst.ShipmentStatus }
  public static get OrderStatusArray() { return WarpConst.ShipmentStatusArray }

  public static getShipmentStatusText(status: string) {
    const mapStatuses = {
      needCarrier: 'Need Carrier',
      booked: 'Booked',
      dispatched: 'Dispatched',
      arrivedAtPickup: 'Arrived At Pickup',
      pickupSuccessful: 'Pickup Successful',
      inRouteToWareHouse: 'In Route To Warehouse',
      arrivedAtWareHouse: 'Arrived At Warehouse',
      departedFromWareHouse: 'Departed From Warehouse',
      pickupFailed: 'Pickup Failed',
      inRouteToDropoff: 'In Route To Dropoff',
      arrivedAtDropoff: 'Arrived At Dropoff',
      dropoffSuccessful: 'Dropoff Successful',
      dropoffFailed: 'Dropoff Failed',
      complete: 'Complete',
      returnInitiated: 'Return Initiated',
      inRouteToReturn: 'In Route To Return',
      arrivedAtReturn: 'Arrived At Return',
      returned: 'Returned',
      canceled: 'Canceled',
      lost: 'Lost',
      issue: 'Issue',
      removed: 'Removed'
    }
    return mapStatuses[status] || mapStatuses.needCarrier
  }

  /**
   * Các bản ghi order trước đây đã được migrate thành shipment
   * @deprecated use getShipmentStatusText instead
   */
  public static getOrderStatusText(status: string) {
    return Const.getShipmentStatusText(status);
  }

  /**
   * Dành cho các bản ghi order mới, sau khi migrate shipment entry
   */
  public static getOrderStatusTextNew(status: OrderStatusNew) {
    if (!status) {
      return 'Created';
    }
    switch (status) {
      case WarpConst.OrderStatusNew.created: return 'Created';
      case WarpConst.OrderStatusNew.inProgress: return 'In Progress';
      case WarpConst.OrderStatusNew.completed: return 'Completed';
      case WarpConst.OrderStatusNew.canceled: return 'Canceled';
      default: return status;
    }
  }

  public static get ContactType() { return WarpConst.ContactType }

  public static get AddressType() { return WarpConst.AddressType }
  public static get AddressTypeArray() { return WarpConst.AddressTypeArray }

  public static get TaskType() { return WarpConst.TaskType }

  public static get TaskStatus() { return WarpConst.TaskStatus }
  public static get TaskStatusArray() { return WarpConst.TaskStatusArray }

  public static get JobStatus() { return WarpConst.JobStatus }
  public static get JobStatusArray() { return WarpConst.JobStatusArray }

  public static get returnReason() {
    return [
      { code: "Customer Unavailable", name: "Customer Unavailable" },
      { code: "Can’t Find Address", name: "Can’t Find Address" },
      { code: "Can’t Access", name: "Can’t Access" },
      { code: "Unsafe Location", name: "Unsafe Location" },
      { code: "Customer Rejected Order", name: "Customer Rejected Order" },
      { code: "Can't Verify Age", name: "Can't Verify Age" },
      { code: "Exceeded Cold Chain", name: "Exceeded Cold Chain" },
      { code: "Lost or damaged goods", name: "Lost or damaged goods" },
      { code: "Other Issue", name: "Other Issue" },
    ]
  }

  public static get CrossdockStatus() { return WarpConst.CrossdockStatus }
  public static get CrossDockJobType() { return WarpConst.CrossDockJobType }
  public static get CrossDockJobTypeArray() { return WarpConst.CrossDockJobTypeArray }

  public static get JobNotificationStatus() { return WarpConst.JobNotificationStatus }
  public static get JobNotificationType() { return WarpConst.JobNotificationType }

  public static get ItemStatus() { return WarpConst.ItemStatus }

  public static getItemStatusText(status: string, warehouseName) {
    if (!warehouseName) warehouseName = "WareHouse"
    const mapStatuses = {
      created: 'Created',
      inRouteToPickup: 'In Route To Pickup',
      pickupSuccessful: 'Pickup Successful',
      pickupFailed: 'Pickup Failed',
      inRouteToWareHouse: `In Route To ${warehouseName} Location`,
      arrivedAtWareHouse: `Arrived At ${warehouseName} Location`,
      inRouteToDeliver: 'In Route to Deliver',
      delivered: 'Delivered',
      deliverFailed: 'Delivered Failed',
      returnInitiated: 'Return Initiated',
      inRouteToReturn: 'In Route to Return',
      arrivedAtReturn: 'Arrived at Return',
      returned: 'Returned',
      canceled: 'Canceled'
    }
    return mapStatuses[status] || "Created"
  }

  public static readonly TaskLocationType = {
    shipment: 'shipment',
    warehouse: 'warehouse',
  }

  static get CostLevel() {
    const order: CostLevelEnum = 'order';
    const shipment: CostLevelEnum = 'shipment';
    return { order, shipment }
  }

  public static get lastMileServices() {
    return [
      { code: "fedex", name: "FedEx" },
    ]
  }

  public static readonly MapBox = {
    FitBoundsBy: {
      routes: 'routes',
      points: 'points',
    },
    style: {
      light: 'streets-v11',
      dark: 'dark-v10'
    }
  }

  public static readonly AppointmentType = WarpConst.AppointmentType;
  public static readonly AppointmentTypeArray = WarpConst.AppointmentTypeArray;
  public static readonly AppointmentContactType = WarpConst.AppointmentContactType;
  public static readonly AppointmentStatus = WarpConst.AppointmentStatus;

  /**
   * @deprecated use before 2024-10-01
   */
  static get allPaymentScheduleLegacy() {
    return [
      { paymentTerm: '24h', factorPercentage: 4 },
      { paymentTerm: '7d', factorPercentage: 3 },
      { paymentTerm: '15d', factorPercentage: 2 },
      { paymentTerm: '30d', factorPercentage: 0 },
    ]
  }

  public static get allPaymentSchedule() {
    return [
      { paymentTerm: '24h', factorPercentage: 8 },
      { paymentTerm: '7d', factorPercentage: 5 },
      { paymentTerm: '15d', factorPercentage: 3 },
      { paymentTerm: '30d', factorPercentage: 0 },
    ]
  }

  static get paymentTermArray() {
    return Const.allPaymentSchedule.map(item => item.paymentTerm);
  }
  public static get ShipmentTypes() { return WarpConst.ShipmentType }
  static get ShipmentTransitType() { return WarpConst.ShipmentTransitType }
  public static readonly FreightQuotePackageType = {
    FTL: 'FTL',
    Pallet: 'Pallet',
    Parcel: 'Parcel'
  }
  public static get packagingTypeList() {
    return [
      Const.FreightQuotePackageType.Parcel,
      Const.FreightQuotePackageType.Pallet,
      Const.FreightQuotePackageType.FTL
    ]
  }

  public static readonly CarrierId = {
    exfreight: '01H22NK3ZVSGS061RVSPXTS56T',
    priority1: '01H22NK88Y5HTN2GH0Q4XND57S'
  }

  public static get CarrierId_Exfreight() { return 59 }   // warpId
  public static get CarrierId_ForwardAir() { return 139 } // warpId

  public static readonly DatEquipments = {
    VAN: 'VAN',
    REEFER: 'REEFER',
    FLATBED: 'FLATBED',
  }

  public static readonly MonenyUnits = {
    us_cent: 'US_cent'
  }
  public static readonly thirdPartyOrderSource = {
    orderful: "orderful",
    walmart: 'walmart',
    DAT: 'DAT',
    Exfreight: 'Exfreight',
    WarpFreightQuote: 'WarpFreightQuote',
    WarpAPIFreightQuote: 'WarpAPIFreightQuote'
  }
  public static readonly QuoteSource = {
    Exfreight: 'Exfreight',
    DAT: 'DAT',
    WARP: 'WARP',
    WarpFreightQuote: 'WarpFreightQuote'
  }
  public static readonly DelayCodeTypes = {
    PICKUP: 'PICKUP',
    DELIVERY: 'DELIVERY',
  }
  public static readonly CarrierAssignmentOptions = {
    before_12hrs: {
      text: "12 Hrs Before 1st Pickup",
      value: -12 * 60
    },
    before_1hr: {
      text: "1 Hr Before 1st Pickup",
      value: -1 * 60
    },
    before_3hrs: {
      text: "3 Hrs Before 1st Pickup",
      value: -2 * 60
    },
    before_6hrs: {
      text: "6 Hrs Before 1st Pickup",
      value: -6 * 60
    },
    before_24hrs: {
      text: "24 Hrs Before 1st Pickup",
      value: -24 * 60
    },
    not_needed: {
      text: "Not Needed",
      value: false
    },
  }
  public static readonly ETAToPickupOptions = {
    before_4hrs: {
      text: "4 Hrs Before Pickup",
      value: -4 * 60
    },
    before_1hr: {
      text: "1 Hr Before 1st Pickup",
      value: -1 * 60
    },
    before_2hrs: {
      text: "2 Hrs Before Pickup",
      value: -2 * 60
    },
    not_needed: {
      text: "Not Needed",
      value: false
    },
  }
  public static readonly PickupSuccessfulOptions = {
    after_1hr: {
      text: "1 Hr After Pickup ETA",
      value: 1 * 60
    },
    after_2hrs: {
      text: "2 Hrs After Pickup ETA",
      value: 2 * 60
    },
    after_4hrs: {
      text: "4 Hrs After Pickup ETA",
      value: 4 * 60
    },
    not_needed: {
      text: "Not Needed",
      value: false
    },
  }
  public static readonly ETAToDeliveryOptions = {
    before_1hr: {
      text: "1 Hr Before 1st Delivery",
      value: -1 * 60
    },
    before_2hrs: {
      text: "2 Hrs Before Delivery",
      value: -2 * 60
    },
    before_4hrs: {
      text: "4 Hrs Before Delivery",
      value: -4 * 60
    },
    not_needed: {
      text: "Not Needed",
      value: false
    },
  }
  public static readonly ETAToDeliveryFrequencyOptions = {
    every_1hr: {
      text: "Every Hour",
      value: 1 * 60
    },
    every_2hrs: {
      text: "Every 2 Hours",
      value: 2 * 60
    },
    every_6hrs: {
      text: "Every 6 Hours",
      value: 6 * 60
    },
    ontime: {
      text: "One Time",
      value: false
    },
  }
  public static readonly DeliverySuccessfulOptions = {
    after_1hr: {
      text: "1 Hr After Delivery ETA",
      value: 1 * 60
    },
    after_2hrs: {
      text: "2 Hrs After Delivery ETA",
      value: 2 * 60
    },
    after_4hrs: {
      text: "4 Hrs After Delivery ETA",
      value: 4 * 60
    },
    not_needed: {
      text: "Not Needed",
      value: false
    },
  }
  public static get ShipmentTypesArray() { return WarpConst.ShipmentTypesArray }

  public static readonly DefaultLengthPallet = 40;
  public static readonly DefaultWidthPallet = 48;
  public static get ShipmentItemUnit() { return WarpConst.ShipmentItemUnit }
  public static get ShipmentQuantityUnits() { return Utils.uniqElementsArray(Object.values(WarpConst.ShipmentItemUnit)) }
  public static get WeightUnits(): WeightUnit[] { return ['lbs', 'kgs'] }   // pounds, kilograms
  public static get SizeUnits(): SizeUnit[] { return ['IN', 'FT'] }       // inches, feet

  public static get ShipmentContainerTypes() {
    return [
      { value: 'pallet', label: 'Pallet' }
    ]
  }

  public static get PalletAuditQuantityUnits() {
    return [
      "Cases",
    ]
  }

  public static inchesToFeet(inValue: number): number {
    return UnitConverter.inchesToFeet(inValue);
  }

  public static feetToInches(ftValue: number): number {
    return UnitConverter.feetToInches(ftValue);
  }

  // convert kilograms (kgs) to pounds (lbs)
  public static kgToLbs(kgsValue: number): number {
    return UnitConverter.kgToLbs(kgsValue);
  }

  // convert pounds (lbs) to kilograms (kgs)
  public static lbsToKgs(lbsValue: number): number {
    return UnitConverter.lbsToKgs(lbsValue);
  }

  public static metersToMiles(meters: number): number {
    return UnitConverter.metersToMiles(meters);
  }
  public static readonly shipmentModeVersion = {
    'version_1': 1,
    'version_2': 2,
  };
  public static convertCentToDollar(cent: number): number {
    return UnitConverter.convertCentToDollar(cent);
  }

  public static readonly OrderCancelReasons = WarpConst.OrderCancelReasons;

  public static readonly thirdPartyClientCode = {
    walmart: "walmart",
    orderful: 'orderful',
  }
  public static get reservationActionCode() {
    return {
      Accepted: "A",
      Cancelled: "D",
      Counter_Proposal_Made: "C"
    }
  }
  public static readonly CurrencyConfig = {
    CAD: {
      value: 'CAD',
      prefix: 'C$'
    },
    USD: {
      value: 'USD',
      prefix: '$'
    }
  }
  public static get InventoryTypes() { return WarpConst.InventoryType }

  public static getInventoryTypeText(type: string) {
    const mapTypes = {
      dock_in: 'Dock Inbound',
      dock_out: 'Dock Outbound',
      in_stage: 'Inbound Stage',
      out_stage: 'Outbound Stage',
      inventory: 'Inventory'
    }
    return mapTypes[type] || ""
  }

  public static LabelTemplateTypes = {
    PALLET: 'PALLET',
    SORT: 'SORT'
  }

  public static getLabelTemplateTypeText(type: string) {
    const mapTypes = {
      PALLET: 'Pallet Label',
      SORT: 'Sorting Label'
    }
    return mapTypes[type] || ""
  }

  public static readonly BatchShipmentType = {
    single_pickup_multi_drop: 1,
    single_drop_multi_pickup: 2,
    multi_pickup_multi_drop: 3,
  }

  public static get WarehouseTypes() {
    return WarpConst.WarehouseType;
  }

  public static getWarehouseTypeText(type: string) {
    const mapTypes = {
      normal: 'Normal',
      crossdock: 'Crossdock',
      external: 'External'
    }
    return mapTypes[type] || ""
  }

  public static get ShipmentEntryMode() { return WarpConst.ShipmentEntryMode }

  static get TransactionTypeNumber() {
    return {
      TRANSACTION_204: 204,
      TRANSACTION_210: 210,
      TRANSACTION_214: 214,
      TRANSACTION_990: 990,
    }
  }

  public static readonly DefaultLocationSettings = WarpConst.DefaultLocationSettings;

  public static readonly CarrierBidTypes = {
    manual: 1, direct: 2
  }

  public static readonly ClassificationShipments = {
    Project: 'Project',
    Consistent: 'Consistent',
    Dedicated: 'Dedicated'
  }

  public static readonly ProductTypes = {
    W2W: 'W2W',
    DCI: 'DCI',
    DSD: 'DSD'
  }

  public static readonly DispatchTab = {
    my_load: 'my_load',
    all_load: 'all_load',
    tracking_task: 'tracking_task',
    archived_queue: 'archived_queue'
  }
  public static readonly WarehouseJobStatus = WarpConst.WarehouseJobStatus;
  public static readonly WarehouseJobStatusText = WarpConst.WarehouseJobStatusText;
  public static readonly WarehouseTaskType = WarpConst.WarehouseTaskType;
  public static readonly WarehouseTaskStatus = WarpConst.WarehouseTaskStatus;
  public static readonly WarehouseTaskName = WarpConst.WarehouseTaskName;
  public static readonly WarehouseTaskStage = WarpConst.WarehouseTaskStage;

  public static readonly CommLogStatuses = [
    { value: 'queued', text: 'Queued', provider: 'TWILIO', type: 'sms', color: 'gray' },
    { value: 'sending', text: 'Sending', provider: 'TWILIO', type: 'sms', color: 'gray' },
    { value: 'sent', text: 'Sent', provider: 'TWILIO', type: 'sms', color: 'green' },
    { value: 'delivered', text: 'Delivered', provider: 'TWILIO', type: 'sms', color: 'blue' },
    { value: 'undelivered', text: 'Undelivered', provider: 'TWILIO', type: 'sms', color: 'red' },
    { value: 'failed', text: 'Failed', provider: 'TWILIO', type: 'sms', color: 'red' },
    { value: 'unsubscribe', text: 'Unsubscribed', provider: 'TWILIO', type: 'sms', color: 'red' },

    { value: 'queued', text: 'Queued', provider: 'PINPOINT', type: 'sms', color: 'gray' },
    { value: 'sending', text: 'Sending', provider: 'PINPOINT', type: 'sms', color: 'gray' },
    { value: 'sent', text: 'Sent', provider: 'PINPOINT', type: 'sms', color: 'green' },
    { value: 'delivered', text: 'Delivered', provider: 'PINPOINT', type: 'sms', color: 'blue' },
    { value: 'undelivered', text: 'Undelivered', provider: 'PINPOINT', type: 'sms', color: 'red' },
    { value: 'failed', text: 'Failed', provider: 'PINPOINT', type: 'sms', color: 'red' },
    { value: 'unsubscribe', text: 'Unsubscribed', provider: 'PINPOINT', type: 'sms', color: 'red' },
    { value: 'successful', text: 'Successful', provider: 'PINPOINT', type: 'sms', color: 'green' },

    { value: 'send', text: 'Processed', provider: 'SES', type: 'email', color: 'gray' },
    { value: 'delivery', text: 'Delivered', provider: 'SES', type: 'email', color: 'blue' },
    { value: 'deliverydelay', text: 'Delivered', provider: 'SES', type: 'email', color: 'blue' },
    { value: 'reject', text: 'Deferred', provider: 'SES', type: 'email', color: 'red' },
    { value: 'renderingfailure', text: 'Dropped', provider: 'SES', type: 'email', color: 'red' },
    { value: 'bounce', text: 'Bounced', provider: 'SES', type: 'email', color: 'red' },
    { value: 'open', text: 'Opened', provider: 'SES', type: 'email', color: 'green' },
    { value: 'click', text: 'Clicked', provider: 'SES', type: 'email', color: 'green' },
    { value: 'complaint', text: 'Spam Reported', provider: 'SES', type: 'email', color: 'red' },
    { value: 'subscription', text: 'Unsubscribed', provider: 'SES', type: 'email', color: 'red' },

    { value: 'processed', text: 'Processed', provider: 'SENDGRID', type: 'email', color: 'gray' },
    { value: 'delivered', text: 'Delivered', provider: 'SENDGRID', type: 'email', color: 'blue' },
    { value: 'deferred', text: 'Deferred', provider: 'SENDGRID', type: 'email', color: 'red' },
    { value: 'dropped', text: 'Dropped', provider: 'SENDGRID', type: 'email', color: 'red' },
    { value: 'bounce', text: 'Bounced', provider: 'SENDGRID', type: 'email', color: 'red' },
    { value: 'open', text: 'Opened', provider: 'SENDGRID', type: 'email', color: 'green' },
    { value: 'click', text: 'Clicked', provider: 'SENDGRID', type: 'email', color: 'green' },
    { value: 'unsubscribe', text: 'Unsubscribed', provider: 'SENDGRID', type: 'email', color: 'red' },
    { value: 'spam_report', text: 'Spam Reported', provider: 'SENDGRID', type: 'email', color: 'red' },
  ]

  public static readonly CarrierSignUpSources = WarpConst.CarrierSignUpSources
  public static readonly CarrierKnownFromSources = WarpConst.CarrierKnownFromSources
  public static readonly CarrierKnownFromSourcesArray = Object.values(WarpConst.CarrierKnownFromSources)
  public static readonly FinanceTransactionStatus = WarpConst.PaymentTransactionStatus
  public static readonly TemplateCodes = WarpConst.TemplateCodes
  public static readonly CarrierStatusGuide = 'https://docs.google.com/document/d/1BdUN4WSgpxvaxGA0ZLm75mbotLKLTqjqw_LPHdCaDTs'

  public static readonly compareBidPrice = {
    more: 1,
    equal: 2,
    less: 3
  }
  public static readonly DenimTrackingStatuses = {
    'pending': 'pending',
    'denim': 'denim',
    'noPOD': 'noPOD',
    'issue': 'issue',
  }
  public static getDenimTrackingStatusText(status: string) {
    const mapStatuses = {
      pending: 'Pending',
      denim: 'Denim',
      noPOD: 'No POD',
      issue: 'Issue',
    }
    return mapStatuses[status] || ""
  }
  public static readonly BroadcastChanelPodConfirmation = "Warp-Pod_Confirmation-Chanel"
  public static readonly CarrierBidState = {
    Refused: 1,
    Accepted: 2,
    PlacedBid: 3
  }
  public static readonly CarrierBidWorkingTimes = {
    start: "09:00:00",
    end: "13:00:00"
  }

  public static readonly trackingTaskStatus = {
    incomplete: 'incomplete',
    complete: 'complete'
  }

  // thứ tự field ảnh hưởng đến TrackingTasksPriorityArr hiển thị UI, lưu ý khi thêm config mới
  public static readonly JobTrackingTaskConfig = {
    CARRIER_CONFIRM: {
      value: 'CARRIER_CONFIRM',
      label: 'Carrier Confirmation Needed',
      isAllowSnooze: true,
    },
    EMPTY_LOAD_CONFIRM: {
      value: 'EMPTY_LOAD_CONFIRM',
      label: 'Empty Load Confirmation Needed',
      isAllowSnooze: true,
    },
    UPDATE_ETA: {
      value: 'UPDATE_ETA',
      label: 'Check Call Needed',
      isAllowSnooze: true,
    },
    ARRIVAL_CONFIRM: {
      value: 'ARRIVAL_CONFIRM',
      label: 'Arrival Confirmation Needed',
      isAllowSnooze: true,
    },
    DEPARTURE_CONFIRM: {
      value: 'DEPARTURE_CONFIRM',
      label: 'Departure Confirmation Needed',
      isAllowSnooze: true,
    },
    CHECK_LIVE_CONNECTION: {
      value: 'CHECK_LIVE_CONNECTION',
      label: 'Live Connection Check Needed',
      isAllowSnooze: true,
    }
  }

  public static get TrackingTasksPriorityArr() {
    return Object.values(this.JobTrackingTaskConfig).map(item => item.value);
  }

  public static get TrackingTasksAllowSnoozeArr() {
    return Object.values(this.JobTrackingTaskConfig).filter(item => item.isAllowSnooze).map(item => item.value);
  }

  public static get MarketPlaceOrderStatus() { return WarpConst.MarketPlaceOrderStatus }
  public static get MarketPlaceOrderStatusArray() { return WarpConst.MarketPlaceOrderStatusArray }

  public static readonly BulkOrderTemp = {
    warpTemplate: 'warpTemplate',
    newTemplate: 'newTemplate'
  }

  public static getPercentageFeeByDayLegacy(dueDays: number): number | null {
    switch (dueDays) {
      case 0: return 4;
      case 1: return 4;
      case 7: return 3;
      case 15: return 2;
      case 30: return 0;
      case 45: return 0;
      case 60: return 0;
      default: return null;
    }
  }

  public static getPercentageFeeByDay(dueDays: number): number | null {
    switch (dueDays) {
      case 0: return 8;
      case 1: return 8;
      case 7: return 5;
      case 15: return 3;
      case 30: return 0;
      case 45: return 0;
      case 60: return 0;
      default: return null;
    }
  }

  public static getExcludeServiceByDay(dueDays: number): string[] {
    switch (dueDays) {
      case 0: return [ 'electric-pallet-jack-rental-pickup' ];
      case 1: return [ 'electric-pallet-jack-rental-pickup' ];
      case 7: return [ 'electric-pallet-jack-rental-pickup' ];
      case 15: return [ 'electric-pallet-jack-rental-pickup' ];
      case 30: return [];
      case 45: return [];
      case 60: return [];
      default: return [];
    }
  }

  public static getPhoneInfoType(type: "MOBILE" | "LANDLINE" | "VOIP" | "INVALID" | "PREPAID" | "OTHER"): string | null {
    switch (type) {
      case "MOBILE": return "Mobile";
      case "LANDLINE": return "Landline";
      case "VOIP": return "VoIP";
      case "INVALID": return "Invalid";
      case "PREPAID": return "Prepaid";
      case "OTHER": return "Other";
      default: return null;
    }
  }
  public static readonly listWarpSystemIds = [
    '01H22NNN7XWAKXJ6SSB10X7MK8'    // cronjob
  ]

  public static readonly CarrierBidType = {
    manual: 1, direct: 2
  }

  public static readonly ListExternalLTLCarrierForDispatchFilter = [
    "01J7ERAXB1D7GH3NA8T9H75HEN", //WWEX
    "01H22NK88Y5HTN2GH0Q4XND57S", // P1
    "01HEXYV55NGMA55ESQC6599FQV", //Approved Freight Forwarders
    "01H22NK3ZVSGS061RVSPXTS56T", //Exfreight
    "01H5X3KBFHVBVXAKEC83JXH6J0", //HTI Logistics
    "01H22NK8GS0VDAASA4ZSKWV785", // Kool Pak
    "01H22NK8SGD6GGAFCWQ5Q994QZ", //H&M Bay
    "01H22NK6WTKZKZD23Q4ETNX93Y", //Frozen Food Express
  ]

  public static get IssueTicketType() { return WarpConst.IssueTicketType }
  public static get IssueTicketStatus() { return WarpConst.IssueTicketStatus }

  public static readonly startRouteHours = 12; // hours

  // public static readonly ListExternalLTLCarrierForDispatchFilter = [
  //   "01J1XFP01BQCKJD1JWRMSVNFR8", // HALO DISTRIBUTION LLC
  //   "01JGWYKD7KZQ6TABWBK3X4RW8W", // Claire Test Carrier No User
  //   "01JGN8EVBZDJK3MA94KCV4GKXP", // Duong Test Push Create Acc
  //   "01JF6MYE22V856J2DX6B549Q4S", // test-hello
  //   "01J98VES7KQ2G311H7SN5PD3Q4", // DHILLON TRUCK LINE EXPRESS INC
  //   "01J72NHM627JDRRPVXCRW4QFA8", // aaa
  //   "01JF9DPFG3M45J7V3B7307P4R3", // Chi test
  //   "01JF7FN131BXEX0PQT61JJ285N", // Janet Test Payment
  // ] // Get ID from dev for testing

  public static readonly shouldAttentionServiceOptions = [
    "two-man-delivery", // Two man delivery
  ]

}
