import { Injectable } from "@angular/core";
import { ApiService } from "@services/api.service";
import { PaginationData } from "@wearewarp/types/common/interface";
import { GetListPaginationOptions } from "@wearewarp/types/rest-api/common";
import { LinehaulLaneDataForFilter, RequestCreateLinehaulLane, ResponseLinehaulLaneListItem } from "@wearewarp/types-server-admin/linehaul-lanes";
import { ApiUri } from "./const";
import { Utils } from "@services/utils";


@Injectable({ providedIn: 'root' })
export class DataRepoLinehaulLane {
  constructor(private api: ApiService) {}

  getList(input: GetListPaginationOptions): Promise<PaginationData<ResponseLinehaulLaneListItem>>  {
    const url = Utils.appendQueryStringIntoUrl(ApiUri.LinehaulLane.getList, input)
    return this.api.get<PaginationData<ResponseLinehaulLaneListItem>>(url).toPromise();
  }

  getDataForFilter() {
    return this.api.get<LinehaulLaneDataForFilter>(ApiUri.LinehaulLane.getDataForFilter).toPromise();
  }

  async getGroups() {
    const ret = await this.getDataForFilter();
    return ret.groups;
  }

  create(data: RequestCreateLinehaulLane): Promise<{id: string}> {
    return this.api.post<{id: string}>(ApiUri.LinehaulLane.create, data).toPromise();
  }
}