import { Injectable } from "@angular/core";
import { ApiService } from "@services/api.service";
import { PaginationData } from "@wearewarp/types/common/interface";
import { ResponseUrlAccessTokenListItem } from "@wearewarp/types-server-admin/factoring-partner-app/admin-settings";
import { ApiUri } from "./const";

@Injectable({ providedIn: 'root' })
export class DataRepoFactoringPartner {
  constructor(private api: ApiService) {}

  getActiveTokens() {
    return this.api.get<PaginationData<ResponseUrlAccessTokenListItem>>(ApiUri.FactoringPartnerSettings.getActiveTokens).toPromise();
  }

  revokeToken(token: string) {
    return this.api.post<void>(ApiUri.FactoringPartnerSettings.revokeToken(token)).toPromise();
  }

  genToken() {
    return this.api.post<{id: string, token: string, expireWhen: string}>(ApiUri.FactoringPartnerSettings.generateToken).toPromise();
  }
}