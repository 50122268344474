import { Injectable } from "@angular/core";
import { ApiService } from "@services/api.service";
import { ApiUri } from "./const";

@Injectable({ providedIn: 'root' })
export class DataRepoWarehouseManagement {
  constructor(private api: ApiService) {}

  getItems(warehouseId: string, shipmentIds: string[]) {
    return this.api.POST(ApiUri.WarehouseManagement.getItems(warehouseId), { shipmentIds });
  }

}