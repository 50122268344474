export class ApiUri {
  static BidCandidate = class {
    private static route(path?: string) { return path ? `v2/carrier_bids/${path}` : `v2/carrier_bids` }
    static bidActivities(candidateId: string) { return this.route(`candidate/${candidateId}/bid_activities`) }
  }

  static BidSession = class {
    private static route(path?: string) { return path ? `v2/bid-sessions/${path}` : `v2/bid-sessions` }
    static readonly create = this.route();
    static detail(id: string) { return this.route(id) }
    static getRoutesRecommend(id: string) { return this.route(`${id}/routes-recommend`) }
    static getRoutesAddded(id: string) { return this.route(`${id}/routes`) }
    static addRoute(id: string) { return this.route(`${id}/add-routes`) }
    static removeRoute(id: string) { return this.route(`${id}/remove-route`) }
    static readonly searchRoutes = this.route(`routes/search`);
    static addCandidates(id: string) { return this.route(`${id}/candidates`) }
  }

  static Client = class {
    private static route(path?: string) { return path ? `v2/clients/${path}` : `v2/clients` }
    static readonly searchForSelection = this.route('search_for_selection');
    static dispatchNote(id: string) { return this.route(`${id}/dispatch_note`) }
  }

  static FactoringPartnerSettings = class {
    private static route(path?: string) { return path ? `v2/factoring-partner-settings/${path}` : `v2/factoring-partner-settings` }
    static readonly getActiveTokens = this.route('active_tokens');
    static readonly generateToken = this.route('gentoken');
    static revokeToken(token: string) { return this.route(`${token}/revoke`) }
  }

  static WarehouseManagement = class {
    static getItems(warehouseId: string) { return `v2/warehouse_management/warehouses/${warehouseId}/items` }
  }

  static LinehaulLane = class {
    private static route(path?: string) { return path ? `v2/linehaul_lanes/${path}` : `v2/linehaul_lanes` }
    static readonly getList = this.route();
    static readonly create = this.route();
    static readonly getDataForFilter = this.route('data_for_filter');
  }

  static Location = class {
    private static route(path?: string) { return path ? `v2/locations/${path}` : `v2/locations` }
    static readonly searchForSelection = this.route('search_for_selection');
  }
}